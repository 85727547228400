<template>
    <div class="calculated-fields-builder__wrapper">
        <div class="calculated-fields-builder">
            <div class="cfb__header">
                <label>Calculated Fields Builder</label>
                <div class="sb__header-actions">
                    <b-btn variant="warning" @click="pasteFromClipboard">Paste from clipboard</b-btn>
                    <b-btn variant="primary" @click="copyToClipboard">Copy</b-btn>
                    <b-btn variant="primary" @click="() => (showImportModal = true)">Import JSON</b-btn>
                    <b-btn variant="primary" @click="downloadJson">Export JSON</b-btn>
                </div>
            </div>
            <div class="cfb__body">
                <b-table-simple
                    :hover="false"
                    striped
                    :show-empty="true"
                    responsive
                    class="calculated-fields-builder"
                >
                    <b-thead>
                        <b-tr>
                            <b-th></b-th>
                            <b-th>ID</b-th>
                            <b-th>Name</b-th>
                            <b-th>Type</b-th>
                            <b-th>Formula</b-th>
                            <b-th>Description</b-th>
                            <b-th></b-th>
                        </b-tr>
                    </b-thead>
                    <draggable v-model="items" handle=".cfb__drag" @input="emitInput" tag="tbody">
                        <b-tr v-for="item in $v.items.$each.$iter" :key="item.uuid.$model">
                            <b-td class="cfb__drag">
                                <i class="fa fa-ellipsis-h cfb__drag-icon" />
                                <i class="fa fa-ellipsis-h cfb__drag-icon" />
                            </b-td>
                            <b-td>
                                <wit-input
                                    v-model="item.id.$model"
                                    placeholder="ID"
                                    @input="emitInput"
                                />
                            </b-td>
                            <b-td>
                                <wit-input
                                    v-model="item.name.$model"
                                    placeholder="Name"
                                    @input="emitInput"
                                />
                            </b-td>
                            <b-td>
                                <wit-select
                                    v-model="item.type.$model"
                                    :options="typeOptions"
                                    placeholder="Type"
                                    @input="emitInput"
                                />
                            </b-td>
                            <b-td>
                                <wit-textarea
                                    v-model="item.formula.$model"
                                    placeholder="Formula"
                                    @input="emitInput"
                                />
                            </b-td>
                            <b-td>
                                <wit-input
                                    v-model="item.description.$model"
                                    placeholder="Description"
                                    @input="emitInput"
                                />
                            </b-td>
                            <b-td>
                                <i
                                    class="fa fa-remove pointer-action"
                                    v-b-tooltip.hover
                                    title="Remove field"
                                    @click="() => removeField(item.uuid.$model)"
                                />
                            </b-td>
                        </b-tr>
                    </draggable>
                    <b-tr>
                        <b-td colspan="7" class="cfb__footer">
                            <i
                                class="fa fa-plus-circle pointer-action"
                                v-b-tooltip.hover
                                title="Add field"
                                @click="addField"
                            />
                        </b-td>
                    </b-tr>
                </b-table-simple>
            </div>
        </div>
        <import-json-modal v-model="showImportModal" @import="importJson" />
    </div>
</template>

<script>
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import VueNotifications from 'vue-notifications'
Vue.use(VueClipboard)

import Draggable from 'vuedraggable'
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'

import {dataStudioTypes} from '@/shared/dataStudioTypes'

const fileDownload = require('js-file-download')

export default {
    components: {
        Draggable,
        ImportJsonModal: () => import('./ImportJsonModal.vue'),
        WitInput: () => import('@/components/Inputs/WitInput.vue'),
        WitTextarea: () => import('@/components/Inputs/WitTextarea.vue'),
        WitSelect: () => import('@/components/Inputs/WitSelect.vue'),
    },
    props: {
        value: {
            type: Array,
        },
        valid: {
            type: Boolean,
        },
    },
    mixins: [validationMixin],
    notifications: {
        importError: {
            type: VueNotifications.types.error,
            timeout: 8000,
            title: 'Could not import CSV. Schema not compatible',
        },
    },
    validations: {
        items: {
            $each: {
                uuid: {},
                id: {required},
                name: {required},
                type: {required},
                formula: {required},
                description: {},
            },
        },
    },
    data() {
        return {
            items: [],
            typeOptions: dataStudioTypes,
            showImportModal: false,
        }
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            if (this.value) {
                this.items = this.value.map(el => ({uuid: this.$randomId(), ...el}))
            }
        },

        emitInput() {
            this.$emit(
                'input',
                this.items.map(({uuid, ...item}) => item)
            )
            this.$emit('update:valid', !this.$v.$invalid)
        },

        addField() {
            this.items.push({
                uuid: this.$randomId(),
                id: '',
                name: '',
                type: null,
                formula: '',
                description: '',
            })
            this.emitInput()
        },

        removeField(uuid) {
            const index = this.items.findIndex(el => el.uuid === uuid)
            this.items.splice(index, 1)
            this.emitInput()
        },

        copyToClipboard() {
            return this.$copyText(this.exportJson())
        },

        async pasteFromClipboard() {
            const json = await navigator.clipboard.readText()
            return this.importJson(json)
        },

        importJson(value) {
            this.$v.items.$model = JSON.parse(value)

            this.$nextTick(() => {
                this.$refs.schemaList.init()
                this.emitInput()
            })
        },

        exportJson() {
            return JSON.stringify(this.items, null, 2)
        },

        downloadJson() {
            return fileDownload(this.exportJson(), 'wit_export_bq_schema.json')
        },
    },
}
</script>

<style lang="scss">
.calculated-fields-builder {
    .wit-select,
    .wit-input {
        min-width: 150px;
    }

    table {
        tr {
            th:first-of-type {
                width: 10px;
                max-width: 10px;
            }

            th:last-of-type {
                width: 10px;
                max-width: 10px;
            }

            th {
                text-transform: uppercase;
                font-weight: 500;
                font-size: 0.75rem;
                color: #7f7f7f;
                background-color: transparent;
                border: 0;
            }

            td {
                border: 0;

                .fa-remove {
                    color: #3c3c3c80;
                    margin-top: 9px;
                }
            }
        }
    }
}

.calculated-fields-builder__wrapper {
    margin-bottom: 15px;
    .calculated-fields-builder {
        border-color: #ddd;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
        .cfb__header {
            color: #333;
            background-color: #f5f5f5;
            padding: 10px 15px;
            border: 1px solid #ddd;
            border-bottom: 1px solid transparent;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            label {
                font-weight: 600;
                margin: 0.25rem 0;
            }

            .sb__header-actions {
                margin: 0 -5px;

                .btn {
                    margin: 0 5px;
                }
            }
        }
        .cfb__body {
            color: #333;
            background-color: white;
            padding: 10px 15px;
            border: 1px solid #ddd;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        .cfb__drag {
            pointer-events: auto;
            cursor: grab;
            text-align: center;
            padding: 2.2rem 0;
            margin-bottom: 5px;
            transform: rotate(-90deg);
            .cfb__drag-icon {
                display: block;
                font-size: 1.2rem;
                line-height: 0.4rem;
                color: #3c3c3c80;
            }
        }

        .cfb__footer {
            text-align: center;
        }
    }
}
</style>
