<template>
    <div class="question-builder__wrapper">
        <div class="question-builder">
            <div class="qb__header">
                <label>Question Builder</label>
            </div>
            <div class="qb__body">
                <div class="qb__add-question-row">
                    <div class="qb__type-select">
                        <wit-select v-model="addQuestionModel" :options="addQuestionTypes" />
                    </div>
                    <b-btn @click="addQuestion" variant="primary">Add Question</b-btn>
                </div>
                <question-list
                    v-model="questions"
                    :valid.sync="questionsValid"
                    @input="emitInput"
                    @update:valid="emitUpdate"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {validationMixin} from 'vuelidate'

import QuestionList from './QuestionList.vue'

const cloneDeep = require('lodash.clonedeep')

export default {
    components: {
        QuestionList,
    },
    props: {
        value: {
            required: true,
        },
        valid: {
            type: Boolean,
        },
    },
    data() {
        return {
            addQuestionModel: {
                id: 'TEXT',
                label: 'Text',
            },
            addQuestionTypes: [
                {
                    id: 'TEXT',
                    label: 'Text',
                },
                {
                    id: 'NUMBER',
                    label: 'Number',
                },
                {
                    id: 'DATE',
                    label: 'Date',
                },
                {
                    id: 'LIST',
                    label: 'List',
                },
                {
                    id: 'RESOURCE',
                    label: 'Resource',
                },
                {
                    id: 'CURRENCY',
                    label: 'Currency',
                },
                {
                    id: 'TIMEZONE',
                    label: 'Timezone',
                },
            ],
            questions: [],
            questionsValid: false,
        }
    },
    mixins: [validationMixin],
    validations: {
        questions: {
            valid: function() {
                return this.questionsValid
            },
        },
    },
    computed: {
        items() {
            return Object.values(this.$v.questions.$each.$iter)
        },
    },
    created() {
        this.questions = this.parseInputQuestions(this.value || [])
        this.emitUpdate()
    },
    methods: {
        parseInputQuestions(value) {
            return value.map(question => {
                const questionCopy = cloneDeep(question)
                if (questionCopy.type === 'LIST') {
                    questionCopy.choices = question.choices.map(choice => ({
                        ...choice,
                        uuid:
                            Math.random()
                                .toString(36)
                                .substring(2, 15) +
                            Math.random()
                                .toString(36)
                                .substring(2, 15),
                    }))
                }

                return {
                    ...questionCopy,
                    uuid:
                        Math.random()
                            .toString(36)
                            .substring(2, 15) +
                        Math.random()
                            .toString(36)
                            .substring(2, 15),
                }
            })
        },

        parseOutputQuestions(value) {
            return value.map(question => {
                const {uuid, ...questionCopy} = cloneDeep(question)

                if (questionCopy.type === 'LIST') {
                    questionCopy.choices = question.choices.map(choice => {
                        const {uuid, name, ...choiceCopy} = cloneDeep(choice)
                        if (name) {
                            return {
                                ...choiceCopy,
                                name,
                            }
                        } else {
                            return choiceCopy
                        }
                    })

                    const {filterBy, filter, tableFilter, ..._question} = questionCopy
                    return _question
                }

                if (
                    questionCopy.type === 'TEXT' ||
                    questionCopy.type === 'DATE' ||
                    questionCopy.type === 'CURRENCY' ||
                    questionCopy.type === 'TIMEZONE'
                ) {
                    const {min, max, choices, multiple, filterBy, filter, tableFilter, ..._question} = questionCopy
                    return _question
                }

                if (questionCopy.type === 'NUMBER') {
                    const {choices, multiple, filterBy, filter, tableFilter, ..._question} = questionCopy
                    return _question
                }

                if (questionCopy.type === 'RESOURCE') {
                    const {choices, tableFilter, ..._question} = questionCopy
                    return _question
                }

                if (questionCopy.type === 'TABLE') {
                    const {min, max, choices, multiple, filterBy, filter, ..._question} = questionCopy
                    return _question
                }
            })
        },

        emitUpdate() {
            this.$emit('update:valid', !this.$v.$invalid)
        },

        emitInput() {
            this.$emit('input', this.parseOutputQuestions(this.questions))
            this.emitUpdate()
        },

        addQuestion() {
            this.questions.push({
                uuid:
                    Math.random()
                        .toString(36)
                        .substring(2, 15) +
                    Math.random()
                        .toString(36)
                        .substring(2, 15),
                type: this.addQuestionModel.id,
                helpText: '',
                variableName: '',
                title: '',
                min: null,
                max: null,
                choices: [],
                multiple: false,
                filterBy: 'Type',
                filter: [],
                tableFilter: '',
                defaultValue: null,
                isAdvanced: false,
            })

            this.emitInput()
        },

        init(value) {
            this.questions = this.parseInputQuestions(value || [])
        },
    },
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';

.push-down-form-row {
    margin-top: 5px;
}

.question-builder__wrapper {
    margin-bottom: 15px;
    .question-builder {
        border-color: #ddd;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
        .qb__header {
            color: #333;
            background-color: #f5f5f5;
            padding: 10px 15px;
            border: 1px solid #ddd;
            border-bottom: 1px solid transparent;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            display: flex;
            align-items: center;
            label {
                font-weight: 600;
                margin: 0.25rem 0;
            }
        }
        .qb__body {
            color: #333;
            background-color: white;
            padding: 10px 15px;
            border: 1px solid #ddd;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            .qb__add-question-row {
                display: flex;
                align-items: center;
                .qb__type-select {
                    width: 200px;
                    display: inline-block;
                    margin-right: 10px;
                }
            }
            .qb__question-wrapper {
                .qb__question {
                    position: relative;
                    pointer-events: auto;
                    cursor: auto;
                    border: 1px solid #ddd;
                    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
                    padding: 15px;
                    padding-top: 0;
                    background-color: #f5f5f5;
                    margin: 15px 0;
                    border-left: 2px solid $brand-color-mustard-yellow;
                    border-radius: 8px;

                    .qb__question-drag {
                        pointer-events: auto;
                        cursor: grab;
                        text-align: center;
                        padding: 0.5rem 0;
                        margin-bottom: 5px;
                        .qb__question-drag-icon {
                            display: block;
                            font-size: 1.2rem;
                            line-height: 0.4rem;
                            color: #3c3c3c80;
                        }
                    }
                    .qb__question-remove-icon-wrapper {
                        pointer-events: auto;
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        color: #3c3c3c80;
                        z-index: 2000;
                        .qb__question-remove-icon {
                            padding: 0.25rem;
                        }
                    }
                    .qb__range {
                        display: flex;
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        align-items: baseline;
                        div {
                            width: 100%;
                        }
                        .qb__range-arrow {
                            padding: 0 0.5rem;
                        }
                    }

                    .qb__choice {
                        position: relative;
                        pointer-events: auto;
                        cursor: auto;
                        border: 1px solid #ddd;
                        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
                        padding: 15px;
                        margin-top: 15px;
                        background-color: white;
                        border-left: 2px solid $brand-color-miami-blue;
                        border-radius: 8px;
                        > .row {
                            align-items: center;
                            > div {
                                > .form-group {
                                    margin-bottom: 0;
                                }
                            }
                        }
                        .qb__choice-drag {
                            pointer-events: auto;
                            cursor: grab;
                            text-align: center;
                            padding: 0.5rem 0;
                            margin-bottom: 5px;
                            transform: rotate(-90deg);
                            .qb__choice-drag-icon {
                                display: block;
                                font-size: 1.2rem;
                                line-height: 0.4rem;
                                color: #3c3c3c80;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
