export const dataStudioTypes = [
    'NUMBER',
    'PERCENT',
    'TEXT',
    'BOOLEAN',
    'URL',
    'HYPERLINK',
    'IMAGE',
    'IMAGE_LINK',
    'YEAR',
    'YEAR_QUARTER',
    'YEAR_MONTH',
    'YEAR_WEEK',
    'YEAR_MONTH_DAY',
    'YEAR_MONTH_DAY_HOUR',
    'YEAR_MONTH_DAY_MINUTE',
    'YEAR_MONTH_DAY_SECOND',
    'QUARTER',
    'MONTH',
    'WEEK',
    'MONTH_DAY',
    'DAY_OF_WEEK',
    'DAY',
    'HOUR',
    'MINUTE',
    'DURATION',
    'COUNTRY',
    'COUNTRY_CODE',
    'CONTINENT',
    'CONTINENT_CODE',
    'SUB_CONTINENT',
    'SUB_CONTINENT_CODE',
    'REGION',
    'REGION_CODE',
    'CITY',
    'CITY_CODE',
    'METRO',
    'METRO_CODE',
    'LATITUDE_LONGITUDE',
    'CURRENCY_USD',
    'CURRENCY_PLN',
    'CURRENCY_EUR',
]
