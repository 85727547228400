<template>
    <div class="wit-custom-query-file-input__wrapper">
        <div :class="['wit-custom-query-file-input']" :id="id" @dragover.prevent @drop.prevent>
            <input type="file" multiple @change="onChange" ref="file" accept=".jpg,.jpeg,.png" />
            <div @drop="dragFile">
                <div v-if="internalValue">
                    {{ internalValue }}
                </div>
                <div v-else class="wit-custom-query-file-input__placeholder">
                    Drop to upload...
                </div>
            </div>
            <button @click="chooseFile">Browse</button>
            <button @click="openGallery" v-if="!noGallery">Gallery</button>
        </div>
        <Feedback v-if="uploadError" :state="uploadError.state" :invalid="uploadError.message"></Feedback>
        <storage-input-modal v-model="showModal" :files="files" @upload="upload" />
        <storage-gallery v-model="showGallery" @choose="chooseFileFromGallery" :chosen="internalValue" />
    </div>
</template>

<script>
import Feedback from '@/components/Feedback.vue'

export default {
    props: {
        value: {
            required: true,
        },
        id: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        noGallery: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Feedback,
        StorageGallery: () => import('./StorageGallery.vue'),
        StorageInputModal: () => import('./StorageInputModal.vue'),
    },
    watch: {
        value() {
            this.internalValue = this.value
        },
    },
    created() {
        this.internalValue = this.value
    },
    data() {
        return {
            internalValue: null,
            files: [],
            showModal: false,
            showGallery: false,
            uploadError: {
                state: null,
                message: '',
            },
        }
    },
    methods: {
        emitInput() {
            this.$emit('input', this.internalValue)
        },

        dragFile(e) {
            e.preventDefault()
            this.$refs.file.files = e.dataTransfer.files
            this.onChange()
        },

        onChange() {
            this.files = [...this.$refs.file.files]
            this.showModal = true
        },

        chooseFile() {
            this.$refs.file.click()
        },

        chooseFileFromGallery(url) {
            this.internalValue = url
            this.emitInput()
        },

        async upload() {
            this.uploadError.state = null
            this.uploadError.message = ''

            const formData = new FormData()
            formData.append('image', this.files[0])

            this.$store.commit('loading/PROCESSING', `Uploading file...`)
            try {
                const response = await this.axios.post(
                    `${process.env.VUE_APP_NODE_API_HOST}/customQuery/storage/upload`,
                    formData
                )
                this.internalValue = response.data.data
                this.emitInput()

                this.$store.commit('loading/PROCESSED')
                this.showModal = false
            } catch (exception) {
                this.$store.commit('loading/PROCESSED')
                this.uploadError.state = false
                this.uploadError.message = 'Could not upload'
                console.error(exception)
            }
        },

        openGallery(e) {
            e.preventDefault()
            this.showGallery = true
        },
    },
}
</script>

<style lang="scss">
.wit-custom-query-file-input__wrapper {
    width: 100%;
    max-width: 100%;

    .wit-custom-query-file-input {
        border: 1px solid #e8e8e8;
        border-radius: 8px;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        color: #3e515b;
        background-color: #fff;
        background-clip: padding-box;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;

        display: flex;
        flex-flow: row nowrap;

        > input {
            display: none;
            visibility: hidden;
            pointer-events: none;
        }

        > div {
            padding: 0.375rem 0.75rem;
            width: 0;
            flex-grow: 1;
            overflow: hidden;
            white-space: nowrap;

            > div {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .wit-custom-query-file-input__placeholder {
                color: #adadad;
            }
        }

        > button {
            height: calc(1.5em + 0.75rem);
            padding: 0.375rem 0.75rem;
            line-height: 1.5;
            color: #3e515b;
            background-color: #f0f3f5;
            border-left: inherit;
            border: 0;
            border-radius: 0;
            border-right: 1px solid #e8e8e8;

            &:last-child {
                border: 0;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
    }
}
</style>
