<template>
    <div v-if="questions.length > 0" class="qb__questions-wrapper">
        <draggable v-model="$v.questions.$model" handle=".qb__question-drag" @input="emitInput">
            <question
                v-for="question in items"
                v-model="questions[question.index]"
                :valid.sync="questionValid[question.uuid]"
                @remove="removeQuestion"
                :key="question.uuid"
                @input="emitInput"
                @update:valid="emitUpdate"
            />
        </draggable>
    </div>
</template>

<script>
import Draggable from 'vuedraggable'
import {validationMixin} from 'vuelidate'

import Feedback from '@/components/Feedback.vue'

const isEqual = require('fast-deep-equal')

export default {
    components: {
        Draggable,
        Feedback,
        Question: () => import('./Question.vue'),
    },
    props: {
        value: {
            required: true,
        },
        valid: {
            type: Boolean,
        },
    },
    data() {
        return {
            questions: [],
            questionValid: {},
        }
    },
    mixins: [validationMixin],
    validations: {
        questions: {
            $each: {
                valid: function(question) {
                    return Boolean(this.questionValid[question.uuid])
                },
            },
        },
    },
    computed: {
        items() {
            return Object.values(this.questions.map((el, index) => ({...el, index})))
        },
    },
    watch: {
        value() {
            if (!isEqual(this.questions, this.value)) {
                this.questions = this.value || []
            }
        },
    },
    created() {
        this.questions = this.value || []
        this.emitUpdate()
    },
    methods: {
        emitUpdate() {
            this.$emit('update:valid', !this.$v.$invalid)
        },

        emitInput() {
            this.$emit('input', this.questions)
            this.emitUpdate()
        },

        removeQuestion(question) {
            this.questions.splice(
                this.questions.findIndex(el => el.uuid === question.uuid.$model),
                1
            )

            this.emitInput()
        },

        validateRef(ref) {
            const keys = ref.split('.')
            const validateField = keys.reduce((a, c) => {
                return a[c]
            }, this.$v)

            return validateField.$dirty ? !validateField.$invalid : null
        },
    },
}
</script>

<style></style>
