<template>
    <div class="schema-builder__wrapper">
        <div class="schema-builder">
            <div class="sb__header">
                <label>Schema Builder</label>
                <div class="sb__header-actions">
                    <b-btn variant="warning" @click="pasteFromClipboard">Paste from clipboard</b-btn>
                    <b-btn variant="primary" @click="copyToClipboard">Copy</b-btn>
                    <b-btn variant="primary" @click="() => (showImportModal = true)">Import JSON</b-btn>
                    <b-btn variant="primary" @click="downloadJson">Export JSON</b-btn>
                </div>
            </div>
            <div class="sb__body">
                <schema-list
                    v-model="$v.items.$model"
                    :valid.sync="schemaValid"
                    @input="emitInput"
                    @update:valid="emitUpdate"
                    ref="schemaList"
                />
            </div>
        </div>
        <import-json-modal v-model="showImportModal" @import="importJson" />
    </div>
</template>

<script>
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import VueNotifications from 'vue-notifications'
Vue.use(VueClipboard)

import {validationMixin} from 'vuelidate'
import {required, minLength} from 'vuelidate/lib/validators'

import SchemaList from './SchemaList'

const fileDownload = require('js-file-download')

export default {
    components: {
        SchemaList,
        ImportJsonModal: () => import('../ImportJsonModal.vue'),
    },
    props: {
        value: {
            type: Array,
        },
        valid: {
            type: Boolean,
        },
    },
    notifications: {
        importError: {
            type: VueNotifications.types.error,
            timeout: 8000,
            title: 'Could not import JSON. Schema not compatible',
        },
    },
    mixins: [validationMixin],
    validations: {
        items: {
            required,
            minLength: minLength(1),
            valid: function() {
                return this.schemaValid
            },
        },
    },
    data() {
        return {
            items: [],
            showImportModal: false,
            schemaValid: false,
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            if (this.value) {
                this.items = this.value
                this.$refs.schemaList.init()
                this.emitUpdate()
            }
        },

        emitInput() {
            this.$emit('input', this.items)
            this.emitUpdate()
        },

        emitUpdate() {
            this.$emit('update:valid', !this.$v.$invalid)
        },

        copyToClipboard() {
            return this.$copyText(this.exportJson())
        },

        async pasteFromClipboard() {
            const json = await navigator.clipboard.readText()
            return this.importJson(json)
        },

        importJson(value) {
            this.$v.items.$model = JSON.parse(value)

            this.$nextTick(() => {
                this.$refs.schemaList.init()
                this.emitInput()
            })
        },

        exportJson() {
            return JSON.stringify(this.items, null, 2)
        },

        downloadJson() {
            return fileDownload(this.exportJson(), 'wit_export_bq_schema.json')
        },
    },
}
</script>

<style lang="scss">
.schema-builder {
    .wit-select,
    .wit-input {
        min-width: 150px;
    }

    table {
        tr {
            th:first-of-type {
                width: 10px;
                max-width: 10px;
            }

            th:last-of-type {
                width: 10px;
                max-width: 10px;
            }

            th {
                text-transform: uppercase;
                font-weight: 500;
                font-size: 0.75rem;
                color: #7f7f7f;
                background-color: transparent;
                border: 0;
            }

            td {
                border: 0;

                .fa-remove {
                    color: #3c3c3c80;
                    margin-top: 9px;
                }
            }
        }
    }
}

.schema-builder__wrapper {
    margin-bottom: 15px;
    .schema-builder {
        border-color: #ddd;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
        .sb__header {
            color: #333;
            background-color: #f5f5f5;
            padding: 10px 15px;
            border: 1px solid #ddd;
            border-bottom: 1px solid transparent;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            label {
                font-weight: 600;
                margin: 0.25rem 0;
            }

            .sb__header-actions {
                margin: 0 -5px;

                .btn {
                    margin: 0 5px;
                }
            }
        }
        .sb__body {
            color: #333;
            background-color: white;
            padding: 10px 15px;
            border: 1px solid #ddd;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            .qb__add-question-row {
                display: flex;
                align-items: center;
                .qb__type-select {
                    width: 200px;
                    display: inline-block;
                    margin-right: 10px;
                }
            }
        }

        .sb__drag {
            pointer-events: auto;
            cursor: grab;
            text-align: center;
            padding: 1.5rem 0;
            margin-bottom: 5px;
            transform: rotate(-90deg);
            .sb__drag-icon {
                display: block;
                font-size: 1.2rem;
                line-height: 0.4rem;
                color: #3c3c3c80;
            }
        }

        .sb__footer {
            text-align: center;
        }
    }
}
</style>
