<template>
    <b-table-simple :hover="false" striped :show-empty="true" responsive class="schema-builder">
        <b-thead>
            <b-tr>
                <b-th></b-th>
                <b-th>Name</b-th>
                <b-th>Mode</b-th>
                <b-th>Type</b-th>
                <b-th>Description</b-th>
                <b-th></b-th>
            </b-tr>
        </b-thead>
        <draggable v-model="$v.items.$model" handle=".sb__drag" @input="emitInput" tag="tbody">
            <template v-for="(item, index) in items">
                <schema-row
                    :key="item.uuid"
                    v-model="items[index]"
                    :valid.sync="itemValid[item.uuid]"
                    @remove="removeField"
                    @input="emitInput"
                    @update:valid="emitUpdate"
                />
                <portal-target
                    :name="`schema-portal-${item.uuid}`"
                    slim
                    :key="`portal-${item.uuid}`"
                />
            </template>
        </draggable>
        <b-tr>
            <b-td colspan="8" class="sb__footer">
                <i
                    class="fa fa-plus-circle pointer-action"
                    v-b-tooltip.hover
                    title="Add field"
                    @click="addField"
                />
            </b-td>
        </b-tr>
    </b-table-simple>
</template>

<script>
import Draggable from 'vuedraggable'
import {PortalTarget} from 'portal-vue'
import {validationMixin} from 'vuelidate'

export default {
    components: {
        Draggable,
        PortalTarget,
        SchemaRow: () => import('./SchemaRow'),
    },
    props: {
        value: {
            type: Array,
        },
        valid: {
            type: Boolean,
        },
    },
    mixins: [validationMixin],
    validations: {
        items: {
            $each: {
                valid: function(item) {
                    return Boolean(this.itemValid[item.uuid])
                },
            },
        },
    },
    data() {
        return {
            items: [],
            itemValid: {},
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            if (this.value) {
                this.items = this.value.map(el => ({uuid: this.$randomId(), ...el}))
                this.emitUpdate()
            }
        },

        emitInput() {
            this.$emit(
                'input',
                this.items.map(({uuid, fields, ...item}) => {
                    if (item.type === 'RECORD') {
                        return {...item, fields}
                    } else return item
                })
            )
            this.emitUpdate()
        },

        emitUpdate() {
            this.$emit('update:valid', !this.$v.$invalid)
        },

        addField() {
            this.items.push({
                uuid: this.$randomId(),
                name: '',
                mode: null,
                type: null,
                fields: [],
                dataStudio: {
                    name: '',
                    type: null,
                    description: '',
                },
            })
            this.emitInput()
        },

        removeField(uuid) {
            const index = this.items.findIndex(el => el.uuid === uuid)
            this.items.splice(index, 1)
            this.emitInput()
        },
    },
}
</script>

<style></style>
