<template>
    <div class="wit-custom-query-storage-icon" v-b-tooltip.hover title="Click to open gallery" @click="openGallery">
        <img :src="internalValue" v-if="internalValue" />
        <div class="wit-custom-query-storage-icon__placeholder" v-show="!internalValue"><i class="fa fa-image" /></div>
        <storage-gallery v-model="showGallery" @choose="chooseFileFromGallery" :chosen="internalValue" />
    </div>
</template>

<script>
export default {
    props: {
        value: {
            required: true,
        },
    },
    components: {
        StorageGallery: () => import('./StorageGallery.vue'),
    },
    watch: {
        value() {
            this.internalValue = this.value
        },
    },
    created() {
        this.internalValue = this.value
    },
    data() {
        return {
            internalValue: null,
            showGallery: false,
        }
    },
    methods: {
        emitInput() {
            this.$emit('input', this.internalValue)
        },

        chooseFileFromGallery(url) {
            this.internalValue = url
            this.emitInput()
        },

        openGallery(e) {
            e.preventDefault()
            this.showGallery = true
        },
    },
}
</script>

<style lang="scss">
.wit-custom-query-storage-icon {
    height: 40px;
    width: 40px;
    cursor: pointer;

    img {
        height: inherit;
        width: inherit;
        object-fit: cover;
    }

    > div {
        width: 40px;
        height: 40px;
        border: 1px dashed #e8e8e8;
        position: relative;
        border-radius: 8px;
        background-color: white;

        i {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
</style>
