<template>
    <div class="animated fadeIn">
        <wit-tabbed-form
            :formId="formId"
            :steps="steps"
            headerText="Setup Your Custom Query"
            @continue="continueForm"
            @reset="resetForm"
            @input="onFormInput"
            ref="tabbedForm"
            @finish="createCustomQuery"
            :details="isDetails"
            :dirty="updateDirty"
            :fetched="fetched"
            :update="updateCustomQuery"
            :hide-native-finish="isDetails"
        >
            <template v-slot:header-right="{anyStepInvalid}">
                <div id="custom-query-button-row">
                    <div class="animate-jump">
                        <transition name="opacity">
                            <b-btn
                                v-if="isDetails && fetched && updateDirty"
                                variant="warning"
                                @click="updateCustomQuery"
                                :disabled="anyStepInvalid"
                                id="custom-query-update"
                            >
                                <i class="icon-check"></i>
                                <span>Update</span>
                            </b-btn>
                        </transition>
                        <transition name="opacity">
                            <b-btn
                                v-if="isDetails && fetched && publishDirty"
                                variant="warning"
                                @click="showPublishModal = true"
                                :disabled="anyStepInvalid"
                                id="custom-query-publish"
                            >
                                <i class="icon-check"></i>
                                <span>Publish</span>
                            </b-btn>
                        </transition>
                    </div>
                    <transition name="opacity">
                        <b-btn
                            v-if="isDetails && fetched"
                            variant="warning"
                            @click="copyCustomQuery"
                            :disabled="anyStepInvalid"
                            id="custom-query-copy"
                        >
                            <i class="fa fa-copy"></i>
                            <span>Copy</span>
                        </b-btn>
                    </transition>
                </div>
            </template>

            <template v-slot:step-1-input-thumbnail>
                <wit-input-group input-label="Thumbnail" v-model="queryForm.image" type="text">
                    <b-row>
                        <b-col md="8">
                            <storage-input v-model="queryForm.image" />
                        </b-col>
                    </b-row>
                </wit-input-group>
            </template>

            <template v-slot:step-2-input-images="{step}">
                <wit-input-group input-label="Preview images" v-model="previewForm.images" type="text">
                    <span v-for="(item, index) in $v.previewForm.images.$each.$iter" :key="index">
                        <b-row class="variable-row">
                            <b-col md="8">
                                <storage-input v-model="item.$model" />
                            </b-col>
                            <b-col md="1">
                                <b-button @click="previewForm.images.splice(index, 1)" size="sm" variant="primary">
                                    <i class="fa fa-minus"></i>
                                </b-button>
                            </b-col>
                        </b-row>
                        <Feedback :state="validateImage(index, step)" invalid="This field is required"></Feedback>
                    </span>
                    <b-row>
                        <b-col md="8">
                            <b-button @click="previewForm.images.push(null)" variant="warning">
                                <i class="fa fa-plus"></i>
                            </b-button>
                        </b-col>
                    </b-row>
                </wit-input-group>
            </template>

            <template v-slot:step-2-input-connectors="{step}">
                <wit-input-group input-label="Required Connectors" v-model="previewForm.requiredConnectors" type="text">
                    <Draggable :list="previewForm.requiredConnectors" group="connectors">
                        <div
                            v-for="(item, index) in $v.previewForm.requiredConnectors.$each.$iter"
                            :key="index"
                            :idx="index"
                        >
                            <b-row class="variable-row">
                                <b-col md="1">
                                    <storage-icon
                                        v-model="$v.previewForm.requiredConnectors.$each[index].image.$model"
                                    />
                                </b-col>
                                <b-col md="7">
                                    <wit-select
                                        placeholder="Type"
                                        v-model="$v.previewForm.requiredConnectors.$each[index].type.$model"
                                        :options="types"
                                    />
                                </b-col>
                                <b-col md="1">
                                    <b-button
                                        @click="previewForm.requiredConnectors.splice(index, 1)"
                                        size="sm"
                                        variant="primary"
                                    >
                                        <i class="fa fa-minus"></i>
                                    </b-button>
                                </b-col>
                            </b-row>
                            <Feedback
                                :state="validateRequiredConnector(index, step)"
                                invalid="This field is required"
                                valid="Field is valid"
                            ></Feedback>
                        </div>
                    </Draggable>
                    <b-row>
                        <b-col md="8">
                            <b-button
                                @click="previewForm.requiredConnectors.push({image: null, type: null})"
                                variant="warning"
                            >
                                <i class="fa fa-plus"></i>
                            </b-button>
                        </b-col>
                    </b-row>
                </wit-input-group>

                <wit-input-group input-label="Optional Connectors" v-model="previewForm.optionalConnectors" type="text">
                    <Draggable :list="previewForm.optionalConnectors" group="connectors">
                        <div
                            v-for="(item, index) in $v.previewForm.optionalConnectors.$each.$iter"
                            :key="index"
                            :idx="index"
                        >
                            <b-row class="variable-row">
                                <b-col md="1">
                                    <storage-icon
                                        v-model="$v.previewForm.optionalConnectors.$each[index].image.$model"
                                    />
                                </b-col>
                                <b-col md="7">
                                    <wit-select
                                        placeholder="Type"
                                        v-model="$v.previewForm.optionalConnectors.$each[index].type.$model"
                                        :options="types"
                                    />
                                </b-col>
                                <b-col md="1">
                                    <b-button
                                        @click="previewForm.optionalConnectors.splice(index, 1)"
                                        size="sm"
                                        variant="primary"
                                    >
                                        <i class="fa fa-minus"></i>
                                    </b-button>
                                </b-col>
                            </b-row>
                            <Feedback
                                :state="validateOptionalConnector(index, step)"
                                invalid="This field is required"
                            ></Feedback>
                        </div>
                    </Draggable>
                    <b-row>
                        <b-col md="8">
                            <b-button
                                @click="previewForm.optionalConnectors.push({image: null, type: null})"
                                variant="warning"
                            >
                                <i class="fa fa-plus"></i>
                            </b-button>
                        </b-col>
                    </b-row>
                </wit-input-group>
            </template>

            <template v-slot:step-2-input-buttons="{step}">
                <wit-input-group input-label="Buttons" v-model="previewForm.buttons" type="text">
                    <span v-for="(item, index) in $v.previewForm.buttons.$each.$iter" :key="index">
                        <b-row class="variable-row">
                            <b-col md="1">
                                <storage-icon v-model="$v.previewForm.buttons.$each[index].image.$model" />
                            </b-col>
                            <b-col md="4">
                                <b-form-input
                                    placeholder="Label"
                                    v-model="$v.previewForm.buttons.$each[index].label.$model"
                                    type="text"
                                ></b-form-input>
                            </b-col>
                            <b-col md="3">
                                <b-form-input
                                    placeholder="Url"
                                    v-model="$v.previewForm.buttons.$each[index].url.$model"
                                    type="text"
                                ></b-form-input>
                            </b-col>
                            <b-col md="1">
                                <b-button @click="previewForm.buttons.splice(index, 1)" size="sm" variant="primary">
                                    <i class="fa fa-minus"></i>
                                </b-button>
                            </b-col>
                        </b-row>
                        <Feedback :state="validateButton(index, step)" invalid="This field is required"></Feedback>
                    </span>
                    <b-row>
                        <b-col md="8">
                            <b-button
                                @click="previewForm.buttons.push({image: null, label: null, url: null})"
                                variant="warning"
                            >
                                Add button<i class="fa fa-plus"></i>
                            </b-button>
                        </b-col>
                    </b-row>
                </wit-input-group>
            </template>

            <template v-slot:step-3-input-templates="{step}">
                <wit-input-group
                    input-label="Data Studio Templates"
                    v-model="versionForm.dataStudioTemplates"
                    type="text"
                >
                    <span v-for="(item, index) in $v.versionForm.dataStudioTemplates.$each.$iter" :key="index">
                        <b-row class="variable-row">
                            <b-col md="3">
                                <b-form-input
                                    placeholder="ID"
                                    v-model="$v.versionForm.dataStudioTemplates.$each[index].id.$model"
                                    type="text"
                                ></b-form-input>
                            </b-col>
                            <b-col md="3">
                                <b-form-input
                                    placeholder="Name"
                                    v-model="$v.versionForm.dataStudioTemplates.$each[index].name.$model"
                                    type="text"
                                ></b-form-input>
                            </b-col>
                            <b-col md="6">
                                <b-form-input
                                    placeholder="Link"
                                    v-model="$v.versionForm.dataStudioTemplates.$each[index].link.$model"
                                    type="text"
                                ></b-form-input>
                            </b-col>
                            <b-col md="1">
                                <b-button
                                    @click="versionForm.dataStudioTemplates.splice(index, 1)"
                                    size="sm"
                                    variant="primary"
                                >
                                    <i class="fa fa-minus"></i>
                                </b-button>
                            </b-col>
                        </b-row>
                        <Feedback :state="validateTemplates(index, step)" invalid="This field is required"></Feedback>
                    </span>
                    <b-row>
                        <b-col md="8">
                            <b-button @click="addTemplate()" variant="warning">
                                Add template <i class="fa fa-plus"></i>
                            </b-button>
                        </b-col>
                    </b-row>
                </wit-input-group>
            </template>

            <template v-slot:step-3-input-queries="{step}">
                <span class="input-label">Queries</span>
                <br /><br />

                <b-card
                    v-for="(query, index) in $v.versionForm.queries.$each.$iter"
                    no-body
                    v-bind:key="index"
                    class="queries-card"
                >
                    <b-button class="accordion-button" block v-b-toggle="'query-accordion-' + index" variant="info">
                        <span class="accordion-button__text"> {{ bindName(index, 'Query') }}</span>
                        <span class="accordion-button__icons">
                            <i
                                v-b-tooltip.hover
                                title="Toggle"
                                v-if="queryAccordion[index]"
                                class="icon fa fa-chevron-down"
                            ></i>
                            <i v-b-tooltip.hover title="Toggle" v-else class="fa fa-chevron-right"></i>

                            <i
                                v-b-tooltip.hover
                                title="Remove"
                                @click.stop="removeQuery(index)"
                                class="icon fa fa-minus"
                            ></i>
                        </span>
                    </b-button>

                    <b-collapse
                        :id="'query-accordion-' + index"
                        class="accordion__queries"
                        accordion="query-accordion"
                        role="tabpanel"
                        visible
                        v-model="queryAccordion[index]"
                    >
                        <wit-input-group input-label="Name" type="text" v-model="versionForm.queries[index].name">
                            <b-row>
                                <b-col md="8">
                                    <b-form-input
                                        placeholder="name"
                                        v-model="$v.versionForm.queries.$each[index].name.$model"
                                        type="text"
                                    ></b-form-input>
                                    <Feedback
                                        :state="validateQueries(index, step, 'name')"
                                        invalid="This field is required"
                                        valid="Field is valid"
                                    ></Feedback>
                                </b-col>
                            </b-row>
                        </wit-input-group>

                        <wit-input-group
                            input-label="Save query results"
                            type="text"
                            v-model="versionForm.queries[index].saveQueryResults"
                        >
                            <b-row>
                                <b-col md="8">
                                    <b-form-checkbox-group class="query-job-lower-row">
                                        <label
                                            class="switch switch-label switch-pill switch-primary switch-show-password"
                                            data-children-count="1"
                                        >
                                            <input
                                                class="switch-input"
                                                type="checkbox"
                                                v-model="$v.versionForm.queries.$each[index].saveQueryResults.$model"
                                            />
                                            <span class="switch-slider" data-checked="On" data-unchecked="Off"></span>
                                        </label>
                                    </b-form-checkbox-group>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col md="8">
                                    <b-form-input
                                        placeholder="Table prefix"
                                        type="text"
                                        v-model="$v.versionForm.queries.$each[index].prefix.$model"
                                        v-show="versionForm.queries[index].saveQueryResults"
                                    ></b-form-input>

                                    <Feedback
                                        :state="validateQueries(index, step, 'prefix')"
                                        invalid="This field is required"
                                        valid="Field is valid"
                                    ></Feedback>
                                </b-col>
                            </b-row>
                        </wit-input-group>

                        <wit-input-group
                            input-label="Partitioned"
                            type="text"
                            v-model="versionForm.queries[index].enablePartitioning"
                            v-show="versionForm.queries[index].saveQueryResults"
                        >
                            <b-row>
                                <b-col md="8">
                                    <b-form-checkbox-group class="query-job-lower-row">
                                        <label
                                            class="switch switch-label switch-pill switch-primary switch-show-password"
                                            data-children-count="1"
                                        >
                                            <input
                                                class="switch-input"
                                                type="checkbox"
                                                v-model="$v.versionForm.queries.$each[index].enablePartitioning.$model"
                                            />
                                            <span class="switch-slider" data-checked="On" data-unchecked="Off"></span>
                                        </label>
                                    </b-form-checkbox-group>
                                </b-col>
                            </b-row>
                        </wit-input-group>

                        <wit-input-group
                            input-label="Partition by"
                            type="text"
                            v-model="$v.versionForm.queries.$each[index].partitionCustomField.$model"
                            v-show="
                                versionForm.queries[index].saveQueryResults &&
                                versionForm.queries[index].enablePartitioning
                            "
                        >
                            <b-row class="query-job-lower-row">
                                <b-col md="8">
                                    <b-form-radio
                                        v-model="$v.versionForm.queries.$each[index].partitionCustomField.$model"
                                        :value="false"
                                        >Ingestion Time</b-form-radio
                                    >
                                    <b-form-radio
                                        v-model="$v.versionForm.queries.$each[index].partitionCustomField.$model"
                                        :value="true"
                                        >Custom Field</b-form-radio
                                    >
                                </b-col>
                            </b-row>
                        </wit-input-group>

                        <wit-input-group
                            input-label
                            type="text"
                            v-model="$v.versionForm.queries.$each[index].partitioning.field.$model"
                            v-show="versionForm.queries[index].partitionCustomField"
                        >
                            <b-row>
                                <b-col md="8">
                                    <b-form-input
                                        placeholder="Custom Field name"
                                        v-model="$v.versionForm.queries.$each[index].partitioning.field.$model"
                                        type="text"
                                    ></b-form-input>
                                </b-col>
                            </b-row>
                        </wit-input-group>

                        <wit-input-group
                            input-label="Clustering"
                            type="text"
                            v-model="versionForm.queries[index].enableClustering"
                            v-show="$v.versionForm.queries.$each[index].saveQueryResults.$model"
                        >
                            <b-row>
                                <b-col md="8">
                                    <b-form-checkbox-group class="query-job-lower-row">
                                        <label
                                            class="switch switch-label switch-pill switch-primary switch-show-password"
                                            data-children-count="1"
                                        >
                                            <input
                                                class="switch-input"
                                                type="checkbox"
                                                v-model="$v.versionForm.queries.$each[index].enableClustering.$model"
                                            />
                                            <span class="switch-slider" data-checked="On" data-unchecked="Off"></span>
                                        </label>
                                    </b-form-checkbox-group>
                                </b-col>
                            </b-row>
                        </wit-input-group>

                        <wit-input-group
                            input-label
                            type="text"
                            v-model="$v.versionForm.queries.$each[index].clustering.fields.$model"
                            v-show="
                                versionForm.queries[index].saveQueryResults &&
                                versionForm.queries[index].enableClustering
                            "
                        >
                            <b-row>
                                <b-col md="8">
                                    <b-form-input
                                        placeholder="Comma-separated list of fields to define clustering order (up to 4)"
                                        v-model="$v.versionForm.queries.$each[index].clustering.fields.$model"
                                        type="text"
                                    ></b-form-input>
                                </b-col>
                            </b-row>
                        </wit-input-group>

                        <wit-input-group
                            input-label="Query"
                            v-model="$v.versionForm.queries.$each[index].query.$model"
                            type="text"
                        >
                            <b-row>
                                <b-col md="8">
                                    <b-form-textarea
                                        id="query"
                                        v-model="$v.versionForm.queries.$each[index].query.$model"
                                        rows="5"
                                    ></b-form-textarea>
                                </b-col>
                            </b-row>
                            <b-row id="queryform-btn-row">
                                <b-col md="8">
                                    <b-button
                                        id="test-query-btn"
                                        @click="openDryRunModal(versionForm.queries[index].query)"
                                        variant="warning"
                                    >
                                        <i class="icon-check"></i> Dry run
                                    </b-button>

                                    <dry-run-modal
                                        v-model="dryRunModal"
                                        v-if="dryRunModal"
                                        :query="dryRunQuery"
                                        :questions="versionForm.questions"
                                        :versions="versions"
                                    />

                                    <Feedback
                                        :state="validateQueries(index, step, 'query')"
                                        invalid="This field is required"
                                        valid="Field is valid"
                                    ></Feedback>
                                </b-col>
                            </b-row>
                        </wit-input-group>
                    </b-collapse>
                </b-card>
                <b-button @click="addQuery" variant="warning">Add Query</b-button>
                <br />
            </template>

            <template v-slot:step-4-input-alignment="{step}">
                <wit-input-group input-label="Auto workflow alignment" v-model="enableAlignmentDateRange" type="text">
                    <b-row>
                        <b-col md="8">
                            <b-form-checkbox-group class="query-job-lower-row">
                                <label
                                    class="switch switch-label switch-pill switch-primary switch-show-password"
                                    data-children-count="1"
                                >
                                    <input class="switch-input" type="checkbox" v-model="enableAlignmentDateRange" />
                                    <span class="switch-slider" data-checked="On" data-unchecked="Off"></span>
                                </label>
                            </b-form-checkbox-group>
                        </b-col>
                    </b-row>
                </wit-input-group>
            </template>

            <template v-slot:step-5-input-question-builder>
                <question-builder
                    v-model="$v.versionForm.questions.$model"
                    :valid.sync="questionsValid"
                    ref="query-builder"
                ></question-builder>
            </template>

            <template v-slot:step-6-input-schema="{step}">
                <b-card
                    v-for="(query, index) in $v.versionForm.queries.$each.$iter"
                    v-show="versionForm.queries[index].saveQueryResults"
                    no-body
                    v-bind:key="index"
                    class="queries-card"
                >
                    <b-button class="accordion-button" block v-b-toggle="'schema-accordion-' + index" variant="info">
                        <span class="accordion-button__text"> {{ bindName(index, 'Schema') }} </span>
                        <span class="accordion-button__icons">
                            <i
                                v-b-tooltip.hover
                                title="Toggle"
                                v-if="schemaAccordion[index]"
                                class="icon fa fa-chevron-down"
                            ></i>
                            <i v-b-tooltip.hover title="Toggle" v-else class="fa fa-chevron-right"></i>
                        </span>
                    </b-button>

                    <b-collapse
                        :id="'schema-accordion-' + index"
                        class="accordion__queries"
                        accordion="schema-accordion"
                        role="tabpanel"
                        visible
                        @show="showSchema(index, versionForm.queries[index].schema)"
                        v-model="schemaAccordion[index]"
                    >
                        <schema-builder
                            v-model="$v.versionForm.queries.$each[index].schema.$model"
                            :valid.sync="schemaValid"
                            :ref="`schema-builder-${index}`"
                        />
                    </b-collapse>
                </b-card>
            </template>

            <template v-if="isDetails" v-slot:step-5-footer="{step}">
                <b-row>
                    <b-col>
                        <Feedback
                            :state="step.checked ? !step.invalid : null"
                            invalid="You need to finish this step first"
                            valid="This step is complete"
                        ></Feedback>
                        <div id="custom-query-footer-button-row">
                            <b-btn
                                variant="primary"
                                @click="updateCustomQuery"
                                :disabled="step.checked && step.invalid"
                            >
                                <i class="icon-check"></i>
                                <span>Update</span>
                            </b-btn>
                            <b-btn
                                variant="primary"
                                @click="showPublishModal = true"
                                :disabled="step.checked && step.invalid"
                            >
                                <i class="icon-check"></i>
                                <span>Publish</span>
                            </b-btn>
                        </div>
                    </b-col>
                </b-row>
            </template>
        </wit-tabbed-form>

        <publish-modal
            v-model="showPublishModal"
            :previous-version="lastVersion"
            :custom-query-error="customQueryError"
            @publish="publishCustomQuery"
        />

        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import VueNotifications from 'vue-notifications'
import Draggable from 'vuedraggable'
import {validationMixin} from 'vuelidate'
import {required, requiredIf} from 'vuelidate/lib/validators'
import {mapGetters} from 'vuex'

import Feedback from '@/components/Feedback.vue'
import WitInputGroup from '@/components/Inputs/WitInputGroup.vue'
import WitTabbedForm from '@/components/WitTabbedForm.vue'
import Loading from '@/components/loading.vue'
import CalculatedFieldsBuilder from './CalculatedFieldsBuilder.vue'
import QuestionBuilder from './QuestionBuilder/QuestionBuilder.vue'
import SchemaBuilder from './SchemaBuilder/SchemaBuilder.vue'
import StorageIcon from './Storage/StorageIcon.vue'
import StorageInput from './Storage/StorageInput.vue'

import {collectTypes} from '@/shared/collectTypes'
import {filterDirty} from '@/shared/filterDirty.js'
import {processTypes} from '@/shared/processTypes'
import {reportTypes} from '@/shared/reportTypes'

import {formMixin} from '@/mixins/formMixin'

const cloneDeep = require('lodash.clonedeep')

export default {
    components: {
        CalculatedFieldsBuilder,
        Draggable,
        Feedback,
        Loading,
        SchemaBuilder,
        Treeselect,
        QuestionBuilder,
        WitTabbedForm,
        StorageIcon,
        StorageInput,
        WitInputGroup,
        DryRunModal: () => import('./DryRunModal'),
        PublishModal: () => import('./PublishModal'),
    },
    data() {
        return {
            queryForm: {
                name: '',
                category: null,
                image: null,
            },
            previewForm: {
                images: [],
                shortDescription: '',
                description: '',
                requiredConnectors: [],
                optionalConnectors: [],
                buttons: [],
                requiredConnectorsCondition: 'ALL',
            },
            versionForm: {
                access: [],
                dataStudioTemplates: [],
                queries: [
                    {
                        name: null,
                        saveQueryResults: true,
                        prefix: null,
                        enablePartitioning: false,
                        partitioning: {
                            field: '',
                        },
                        enableClustering: false,
                        clustering: {
                            fields: '',
                        },
                        query: null,
                        schema: [],
                    },
                ],
                questions: [],
                ongoingSchedule: '*/30 * * * *',
                ongoingDateRange: {
                    start: 0,
                    end: 0,
                },
                alignmentDateRange: {
                    start: 30,
                    end: 1,
                },
                skipHistoricalWorkflow: false,
                historicalWorkflowMax: 365,
            },
            enableOngoingDateRange: false,
            enableAlignmentDateRange: false,
            currentEnableOngoingDateRange: false,
            currentEnableAlignmentDateRange: false,
            currentQuery: {},
            currentPreview: {},
            currentVersionForm: {},
            fromTable: {
                shard: '`{{CLOUD_PROJECT_ID}}.{{DATASET_ID}}.{{TABLE_ID}}_{{SHARD_DATE}}`',
                all: '`{{CLOUD_PROJECT_ID}}.{{DATASET_ID}}.{{TABLE_ID}}_*`',
            },
            categories: [],
            requiredConnectorsConditionOptions: ['ALL', 'ANY'],
            dryRunModal: false,
            dryRunQuery: '',
            customQueryError: {
                state: null,
                message: null,
            },
            partitionCustomField: false,
            questionsValid: false,
            schemaValid: false,
            chosenAccess: [],
            fetched: false,
            cannotContinue: true,
            versions: [],
            currentVersion: '',
            showPublishModal: false,
            queryAccordion: [],
            schemaAccordion: [],
        }
    },
    mixins: [formMixin, validationMixin],
    validations: {
        enableOngoingDateRange: {},
        currentVersion: {},
        queryForm: {
            name: {required},
            category: {required},
            image: {},
        },
        previewForm: {
            images: {
                $each: {required},
            },
            shortDescription: {},
            description: {},
            requiredConnectors: {
                $each: {
                    image: {required},
                    type: {required},
                },
            },
            optionalConnectors: {
                $each: {
                    image: {required},
                    type: {required},
                },
            },
            buttons: {
                $each: {
                    image: {required},
                    label: {required},
                    url: {required},
                },
            },
            requiredConnectorsCondition: {required},
        },
        versionForm: {
            access: {},
            options: {},
            queries: {
                $each: {
                    name: {required},
                    prefix: {
                        required: requiredIf(function (value) {
                            return value.saveQueryResults
                        }),
                    },
                    saveQueryResults: {},
                    enablePartitioning: {},
                    partitionCustomField: {},
                    partitioning: {
                        field: {},
                    },
                    enableClustering: {},
                    clustering: {
                        fields: {},
                    },
                    query: {required},
                    schema: {
                        // required: requiredIf(function (value) {
                        //     return value.saveQueryResults
                        // }),
                    },
                },
            },
            questions: {
                valid: function () {
                    return this.questionsValid
                },
            },
            dataStudioTemplates: {
                $each: {
                    id: {required},
                    name: {required},
                    link: {required},
                },
            },
            ongoingSchedule: {},
            ongoingDateRange: {
                start: {},
                end: {},
            },
            alignmentDateRange: {
                start: {},
                end: {},
            },
            skipHistoricalWorkflow: {},
            historicalWorkflowMax: {},
        },
    },
    notifications: {
        published: {
            type: VueNotifications.types.success,
        },
        updated: {
            type: VueNotifications.types.success,
            timeout: 8000,
            title: 'Custom Query successfully updated',
        },
    },
    async created() {
        this.$store.commit('loading/PROCESSING', `Loading form...`)

        if (!this.isDetails) {
            this.categories = await this.fetchCategories()

            if (this.$route.query.cid && this.$route.query.cvid) {
                await Promise.all([this.fetchQuery(this.$route.query.cid), this.fetchVersions(this.$route.query.cid)])
                this.currentVersion = decodeURIComponent(this.$route.query.cvid)
                await this.fetchVersion(this.$route.query.cid)
                setTimeout(() => {
                    this.$refs['query-builder'].init(this.versionForm.questions)
                    // this.$refs['schema-builder'].init(this.versionForm.schema)
                }, 250)
            }

            const formSessionMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
            const formSessionId = formSessionMap[this.$route.path].id

            this.$gtm.trackEvent({
                event: 'create_resource_start',
                action: 'create',
                value: formSessionId,
            })
        } else {
            await Promise.all([this.fetchQuery(), this.fetchVersions()])
            await this.fetchVersion()
            this.categories = await this.fetchCategories()

            this.$refs['query-builder'].init(this.versionForm.questions)

            this.fetched = true
        }

        this.$store.commit('loading/PROCESSED')
    },
    computed: {
        ...mapGetters({
            loading: 'loading/state',
            projects: 'project/projects',
        }),
        isDetails() {
            return Boolean(this.$route.params.id)
        },
        formId() {
            return this.isDetails ? 'custom-query-create-form' : 'custom-query-details-form'
        },
        steps() {
            return [
                {
                    name: 'General Settings',
                    invalid: this.$v.queryForm.$invalid,
                    inputs: [
                        {
                            name: 'current-version',
                            value: this.$v.currentVersion.$model,
                            model: this.$v.currentVersion,
                            type: 'select',
                            inputLabel: 'Current Version',
                            options: this.versions,
                            vIf: this.isDetails,
                            allowEmpty: false,
                            onInput: this.fetchVersion,
                        },
                        {
                            name: 'name',
                            value: this.$v.queryForm.name.$model,
                            model: this.$v.queryForm.name,
                            type: 'text',
                            inputLabel: 'Query name',
                            placeholder: 'Enter your query name',
                            invalid: 'This field is required',
                            valid: 'Name is valid',
                        },
                        {
                            name: 'category',
                            value: this.$v.queryForm.category.$model,
                            model: this.$v.queryForm.category,
                            type: 'select',
                            inputLabel: 'Category',
                            placeholder: 'Select category',
                            options: this.categories,
                            invalid: 'This field is required',
                            valid: 'Category is valid',
                        },
                        {
                            name: 'thumbnail',
                        },
                    ],
                },
                {
                    name: 'Preview Settings',
                    invalid: this.$v.queryForm.$invalid || this.$v.previewForm.$invalid,
                    inputs: [
                        {
                            name: 'images',
                        },
                        {
                            name: 'connectors-condition',
                            value: this.$v.previewForm.requiredConnectorsCondition.$model,
                            model: this.$v.previewForm.requiredConnectorsCondition,
                            type: 'select',
                            inputLabel: 'Required connectors condition',
                            options: this.requiredConnectorsConditionOptions,
                            invalid: 'This field is required',
                            valid: 'Condition is valid',
                        },
                        {
                            name: 'connectors',
                        },
                        {
                            name: 'short-description',
                            value: this.$v.previewForm.shortDescription.$model,
                            model: this.$v.previewForm.shortDescription,
                            type: 'textarea',
                            inputLabel: 'Short Description',
                            placeholder: 'Enter your short description',
                            valid: 'Short description is valid',
                        },
                        {
                            name: 'description',
                            value: this.$v.previewForm.description.$model,
                            model: this.$v.previewForm.description,
                            type: 'markdown',
                            inputLabel: 'Description',
                            placeholder: 'Enter your description',
                            valid: 'Description is valid',
                        },
                        {
                            name: 'buttons',
                        },
                    ],
                },
                {
                    name: 'Version Settings',
                    invalid: this.$v.queryForm.$invalid || this.$v.versionForm.$invalid,
                    inputs: [
                        {
                            name: 'access',
                            value: this.$v.versionForm.access.$model,
                            model: this.$v.versionForm.access,
                            type: 'treeselect',
                            inputLabel: 'Access',
                            placeholder: 'Grant access',
                            options: this.accessOptions,
                            invalid: 'This field is required',
                            valid: 'Query access is valid',
                            defaultExpandLevel: Infinity,
                            beforeClearAll: () => (this.versionForm.access = []),
                            multiple: true,
                            valueFormat: 'id',
                            valueConsistsOf: 'LEAF_PRIORITY',
                        },
                        {
                            name: 'templates',
                        },

                        {
                            name: 'queries',
                        },
                    ],
                },
                {
                    name: 'Workflow Settings',
                    invalid: this.$v.queryForm.$invalid || this.$v.versionForm.$invalid,
                    inputs: [
                        {
                            name: 'alignment',
                        },
                        {
                            name: 'alignment-start',
                            vIf: this.enableAlignmentDateRange,
                            value: this.$v.versionForm.alignmentDateRange.start.$model,
                            model: this.$v.versionForm.alignmentDateRange.start,
                            type: 'range',
                            inputLabel: 'Alignment workflow daterange start',
                            min: 0,
                            max: 365,
                        },
                        {
                            name: 'alignment-end',
                            vIf: this.enableAlignmentDateRange,
                            value: this.$v.versionForm.alignmentDateRange.end.$model,
                            model: this.$v.versionForm.alignmentDateRange.end,
                            type: 'range',
                            inputLabel: 'Alignment workflow daterange end',
                            min: 0,
                            max: 365,
                        },
                        {
                            name: 'ongoing',
                            value: this.$v.enableOngoingDateRange.$model,
                            model: this.$v.enableOngoingDateRange,
                            type: 'checkbox',
                            inputLabel: 'Auto workflow ongoing',
                            checked: 'On',
                            unchecked: 'Off',
                        },
                        {
                            name: 'ongoing-schedule',
                            vIf: this.enableOngoingDateRange,
                            value: this.$v.versionForm.ongoingSchedule.$model,
                            model: this.$v.versionForm.ongoingSchedule,
                            type: 'cron',
                            inputLabel: 'Ongoing Schedule',
                            visibleEveryXMinutes: true,
                        },
                        {
                            name: 'ongoing-start',
                            vIf: this.enableOngoingDateRange,
                            value: this.$v.versionForm.ongoingDateRange.start.$model,
                            model: this.$v.versionForm.ongoingDateRange.start,
                            type: 'range',
                            inputLabel: 'Ongoing workflow daterange start',
                            min: 0,
                            max: 365,
                        },
                        {
                            name: 'ongoing-end',
                            vIf: this.enableOngoingDateRange,
                            value: this.$v.versionForm.ongoingDateRange.end.$model,
                            model: this.$v.versionForm.ongoingDateRange.end,
                            type: 'range',
                            inputLabel: 'Ongoing workflow daterange end',
                            min: 0,
                            max: 365,
                        },
                        {
                            name: 'skip-historical-workflow',
                            value: this.$v.versionForm.skipHistoricalWorkflow.$model,
                            model: this.$v.versionForm.skipHistoricalWorkflow,
                            type: 'checkbox',
                            inputLabel: 'Skip historical workflow',
                            checked: 'On',
                            unchecked: 'Off',
                        },
                        {
                            name: 'historical-max',
                            vIf: !this.versionForm.skipHistoricalWorkflow,
                            value: this.$v.versionForm.historicalWorkflowMax.$model,
                            model: this.$v.versionForm.historicalWorkflowMax,
                            type: 'range',
                            inputLabel: 'Historical workflow max range',
                            min: 0,
                            max: 365,
                        },
                    ],
                },
                {
                    name: 'Question Builder',
                    invalid:
                        this.$v.queryForm.$invalid ||
                        this.$v.versionForm.access.$invalid ||
                        this.$v.versionForm.questions.$invalid,
                    inputs: [
                        {
                            name: 'question-builder',
                        },
                    ],
                },
                {
                    name: 'Schema Builder',
                    invalid: this.$v.queryForm.$invalid || this.$v.versionForm.$invalid,
                    inputs: [
                        {
                            name: 'schema',
                        },
                    ],
                },
            ]
        },
        updateDirty() {
            const queryDirty = filterDirty(this.queryForm, this.currentQuery)
            const previewDirty = filterDirty(this.previewForm, this.currentPreview)
            return Object.keys(queryDirty).length > 0 || Object.keys(previewDirty).length > 0
        },
        publishDirty() {
            const versionDirty = filterDirty(
                {
                    ...this.versionForm,
                    enableOngoingDateRange: this.enableOngoingDateRange,
                    enableAlignmentDateRange: this.enableAlignmentDateRange,
                },
                {
                    ...this.currentVersionForm,
                    enableOngoingDateRange: this.currentEnableOngoingDateRange,
                    enableAlignmentDateRange: this.currentEnableAlignmentDateRange,
                }
            )
            return Object.keys(versionDirty).length > 0
        },
        accessOptions() {
            return [
                ...this.projects.map((project) => ({
                    id: project.id,
                    label: project.name,
                })),
                ...this.chosenAccess.filter((el) => el.unavailable),
            ]
        },
        lastVersion() {
            return this.versions.length ? this.versions[this.versions.length - 1] : null
        },
        types() {
            return [...collectTypes, ...processTypes, ...reportTypes]
                .map((el) => ({
                    type: el.type,
                    subtype: el.subtype,
                }))
                .map((el) => `${el.type} ${el.subtype}`)
        },
        schemaQueries() {
            return this.versionForm.queries.filter(({saveQueryResults}) => saveQueryResults)
        },
    },
    methods: {
        async fetchQuery(_id) {
            const id = _id ? _id : this.$route.params.id
            const {
                data: {data},
            } = await this.axios.get(`${process.env.VUE_APP_NODE_API_HOST}/customQuery/${id}`)

            this.customQuery = data
            this.queryForm = {
                name: `${data.name}${_id ? ' copy' : ''}`,
                image: data.image,
                category: data.category,
            }

            let preview = {}
            if (data.preview) {
                const {
                    images,
                    shortDescription,
                    description,
                    requiredConnectorsCondition,
                    requiredConnectors,
                    optionalConnectors,
                    buttons,
                } = data.preview

                preview = {
                    images,
                    shortDescription,
                    description,
                    requiredConnectorsCondition,
                    requiredConnectors,
                    optionalConnectors,
                    buttons,
                }
            }
            this.previewForm = {...this.previewForm, ...preview}

            this.$nextTick(() => {
                this.$v.$reset()
            })

            this.currentQuery = cloneDeep(this.queryForm)
            this.currentPreview = cloneDeep(this.previewForm)
        },

        async fetchVersions(_id) {
            const id = _id ? _id : this.$route.params.id

            const {
                data: {data: versions},
            } = await this.axios.get(`${process.env.VUE_APP_NODE_API_HOST}/customQuery/versions/${id}`)

            this.versions = versions
        },

        async fetchVersion(_id) {
            const id = _id ? _id : this.$route.params.id

            if (!this.currentVersion) {
                this.currentVersion = this.lastVersion
            }

            const {
                data: {data: versionData},
            } = await this.axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/customQuery/versions/${id}/${encodeURIComponent(
                    this.currentVersion
                )}`
            )

            this.chosenAccess =
                versionData && versionData.access
                    ? versionData.access.map((access) => {
                          const {id} = access

                          if (id === 'All') {
                              return {id: 'All', label: 'all'}
                          }

                          const project = this.projects.find((project) => project.id === id)
                          if (project) {
                              return {id: project.id, label: project.name}
                          } else {
                              return {
                                  id,
                                  label: 'Name unavailable',
                                  unavailable: true,
                              }
                          }
                      })
                    : []

            if (versionData && versionData.alignmentDateRange) {
                this.currentEnableAlignmentDateRange = true
                this.enableAlignmentDateRange = true
            }

            if (versionData && (versionData.ongoingDateRange || versionData.ongoingSchedule)) {
                this.currentEnableOngoingDateRange = true
                this.enableOngoingDateRange = true
            }

            if (versionData && versionData.queries) {
                versionData.queries.forEach((query) => {
                    if (query.clustering) {
                        query.clustering.fields = query.clustering.fields.join(',')
                    }
                })
            }

            const versionForm = {
                access: versionData.access ? versionData.access.map((access) => access.id) : [],
                dataStudioTemplates: versionData.dataStudioTemplates ? versionData.dataStudioTemplates : [],
                queries: versionData.queries ? versionData.queries : [],
                questions: versionData.questions,
                alignmentDateRange: versionData.alignmentDateRange
                    ? versionData.alignmentDateRange
                    : {start: 31, end: 1},
                ongoingDateRange: versionData.ongoingDateRange ? versionData.ongoingDateRange : {start: 0, end: 0},
                ongoingSchedule: versionData.ongoingSchedule || '*/30 * * * *',
                skipHistoricalWorkflow: versionData.skipHistoricalWorkflow || false,
                historicalWorkflowMax: Object.prototype.hasOwnProperty.call(versionData, 'historicalWorkflowMax')
                    ? versionData.historicalWorkflowMax
                    : 365,
            }

            this.versionForm = {...this.versionForm, ...versionForm}

            this.currentVersionForm = cloneDeep(this.versionForm)

            this.$nextTick(() => {
                this.$v.$reset()

                this.$refs['query-builder'].init(this.versionForm.questions)
                // this.$refs['schema-builder'].init(this.versionForm.schema)
            })
        },

        async fetchCategories() {
            const response = await this.axios.get(`${process.env.VUE_APP_NODE_API_HOST}/customQuery/categories`)

            return response.data.data.map(({name}) => name)
        },

        async updateCustomQuery() {
            const queryDirty = filterDirty(this.queryForm, this.currentQuery)
            const previewDirty = filterDirty(this.previewForm, this.currentPreview)

            if (Object.keys(queryDirty).length > 0 || Object.keys(previewDirty).length > 0) {
                this.customQueryError.state = null
                this.customQueryError.message = ''

                const data = {
                    name: queryDirty.name,
                    category: queryDirty.category,
                    image: queryDirty.image,
                    preview: Object.keys(previewDirty).length > 0 ? {...this.previewForm} : undefined,
                }

                this.$store.commit('loading/PROCESSING', `Updating...`)
                try {
                    await this.axios.put(
                        `${process.env.VUE_APP_NODE_API_HOST}/customQuery/${this.customQuery.id}`,
                        data
                    )

                    this.customQueryError = {}
                    this.currentQuery = cloneDeep(this.queryForm)
                    this.$forceUpdate()
                    this.$v.$reset()
                    this.updated()

                    this.$store.commit('loading/PROCESSED')
                } catch (exception) {
                    this.$store.commit('loading/PROCESSED')
                    this.customQueryError.state = false
                    this.customQueryError.message = exception.response.data.data
                }
            } else {
                this.customQueryError.state = false
                this.customQueryError.message = 'Nothing changed'
            }
        },

        async publishCustomQuery({version, migrationGuide}) {
            const versionDirty = filterDirty(
                {
                    ...this.versionForm,
                    enableOngoingDateRange: this.enableOngoingDateRange,
                    enableAlignmentDateRange: this.enableAlignmentDateRange,
                },
                {
                    ...this.currentVersionForm,
                    enableOngoingDateRange: this.currentEnableOngoingDateRange,
                    enableAlignmentDateRange: this.currentEnableAlignmentDateRange,
                }
            )

            if (Object.keys(versionDirty).length > 0) {
                this.customQueryError.state = null
                this.customQueryError.message = ''

                const data = {
                    version,
                    migrationGuide,
                    previousVersionId: this.lastVersion,
                    customQueryId: this.customQuery.id,
                    access: this.versionForm.access,
                    dataStudioTemplates: this.versionForm.dataStudioTemplates,
                    queries: this.versionForm.queries,
                    questions: this.versionForm.questions,
                    alignmentDateRange: this.enableAlignmentDateRange ? this.versionForm.alignmentDateRange : undefined,
                    ongoingDateRange: this.enableOngoingDateRange ? this.versionForm.ongoingDateRange : undefined,
                    ongoingSchedule: this.enableOngoingDateRange ? this.versionForm.ongoingSchedule : undefined,
                    skipHistoricalWorkflow: this.versionForm.skipHistoricalWorkflow
                        ? this.versionForm.skipHistoricalWorkflow
                        : undefined,
                    historicalWorkflowMax: !this.versionForm.skipHistoricalWorkflow
                        ? this.versionForm.historicalWorkflowMax
                        : undefined,
                }

                this.$store.commit('loading/PROCESSING', `Publishing...`)
                try {
                    const response = await this.axios.post(
                        `${process.env.VUE_APP_NODE_API_HOST}/customQuery/versions`,
                        data
                    )

                    this.customQueryError = {}
                    this.showPublishModal = false
                    await this.fetchVersions()
                    this.currentVersion = response.data.data.id
                    await this.fetchVersion()
                    this.$forceUpdate()
                    this.$v.$reset()
                    this.published({
                        title: 'Successfully published a new version',
                        message: `Version number: ${this.currentVersion}`,
                        timeout: 8000,
                    })

                    this.$store.commit('loading/PROCESSED')
                } catch (exception) {
                    this.$store.commit('loading/PROCESSED')
                    this.customQueryError.state = false

                    const {errorResponse} = exception.response.data.metadata
                    this.customQueryError.message = `<strong>Errors</strong>: <br/> ${errorResponse.message.join(
                        '<br/>'
                    )}`
                }
            } else {
                this.customQueryError.state = false
                this.customQueryError.message = 'Nothing changed'
            }
        },

        async createCustomQuery(step) {
            step.check()
            this.$forceUpdate()

            if (!this.$v.queryForm.$invalid && !this.$v.versionForm.$invalid) {
                this.customQueryError.state = null
                this.customQueryError.message = ''

                const version = {
                    access: this.versionForm.access,
                    queries: this.versionForm.queries,
                    questions: this.versionForm.questions,
                    alignmentDateRange: this.enableAlignmentDateRange ? this.versionForm.alignmentDateRange : undefined,
                    ongoingDateRange: this.enableOngoingDateRange ? this.versionForm.ongoingDateRange : undefined,
                    ongoingSchedule: this.enableOngoingDateRange ? this.versionForm.ongoingSchedule : undefined,
                    skipHistoricalWorkflow: this.versionForm.skipHistoricalWorkflow
                        ? this.versionForm.skipHistoricalWorkflow
                        : undefined,
                    historicalWorkflowMax: !this.versionForm.skipHistoricalWorkflow
                        ? this.versionForm.historicalWorkflowMax
                        : undefined,
                }

                const data = {
                    name: this.queryForm.name,
                    category: this.queryForm.category,
                    version,
                    image: this.queryForm.image,
                    preview: this.previewForm,
                }

                this.$store.commit('loading/PROCESSING', `Creating Custom Query`)
                try {
                    await this.axios.post(`${process.env.VUE_APP_NODE_API_HOST}/customQuery`, data)

                    this.$store.commit('loading/PROCESSED')
                    this.customQueryError = {}

                    const formSessionMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
                    const formSessionId = formSessionMap[this.$route.path].id

                    this.$gtm.trackEvent({
                        event: 'create_resource_finish',
                        action: 'finish',
                        value: formSessionId,
                    })

                    formSessionMap[this.$route.path] = null
                    localStorage.setItem('formSessionIdMap', JSON.stringify(formSessionMap))

                    this.$projectRouter.push('/customqueries?queryCreated=true')
                } catch (exception) {
                    this.$store.commit('loading/PROCESSED')
                    this.customQueryError.state = false
                    this.customQueryError.message = exception.response.data.data
                }
            }
        },

        queryOptionsNormalizer(node) {
            return {
                id: node,
                label: node,
            }
        },

        openDryRunModal(query) {
            this.dryRunModal = true
            this.dryRunQuery = query || ''
        },

        changeFromTable(node) {
            if (node === 'Support Partitioning') {
                const supportPartitioning = !this.versionForm.options.includes('Support Partitioning')
                this.versionForm.query = supportPartitioning
                    ? this.versionForm.query.replace(this.fromTable.all, this.fromTable.shard)
                    : this.versionForm.query.replace(this.fromTable.shard, this.fromTable.all)
            }
        },

        validateTemplates(index, step) {
            const {id, name, link} = this.$v.versionForm.dataStudioTemplates.$each[index]
            if (step.checked) return !id.$invalid && !name.$invalid && !link.$invalid
            return id.$dirty && name.$dirty ? !id.$invalid && !name.$invalid && !link.$invalid : null
        },

        validateRequiredConnector(index, step) {
            const {image, type} = this.$v.previewForm.requiredConnectors.$each[index]
            if (step.checked) return !image.$invalid && !type.$invalid
            return image.$dirty && type.$dirty ? !image.$invalid && !type.$invalid : null
        },

        validateOptionalConnector(index, step) {
            const {image, type} = this.$v.previewForm.optionalConnectors.$each[index]
            if (step.checked) return !image.$invalid && !type.$invalid
            return image.$dirty && type.$dirty ? !image.$invalid && !type.$invalid : null
        },

        validateQueries(index, step, property) {
            const query = this.$v.versionForm.queries.$each[index]
            const field = query[property]

            if (step.checked) return !field.$invalid
            return field.$dirty ? !field.$invalid : null
        },

        validateButton(index, step) {
            const {image, label, url} = this.$v.previewForm.buttons.$each[index]
            if (step.checked) return !image.$invalid && !label.$invalid && !url.$invalid
            return image.$dirty && name.$dirty && url.$dirty ? !image.$invalid && !name.$invalid && !url.$invalid : null
        },

        validateImage(index, step) {
            const image = this.$v.previewForm.images.$each[index]
            if (step.checked) return !image.$invalid
            return image.$dirty ? !image.$invalid : null
        },

        validateRef(ref, step) {
            const keys = ref.split('.')
            const validateField = keys.reduce((a, c) => {
                return a[c]
            }, this.$v)

            if (step.checked) return !validateField.$invalid
            return validateField.$dirty ? !validateField.$invalid : null
        },

        copyCustomQuery() {
            this.$projectRouter.push(
                `/customqueries/create?cid=${this.$route.params.id}&cvid=${encodeURIComponent(this.currentVersion)}`
            )
        },

        addTemplate() {
            this.versionForm.dataStudioTemplates.push({
                id: null,
                name: null,
                link: `https://datastudio.google.com/u/0/reporting/create?r.reportName={{REPORT_NAME}}&c.reportId={{REPORT_ID}}&ds.connector=bigQuery&ds.type=TABLE&ds.projectId={{PROJECT_ID}}&ds.datasetId={{DATASET_ID}}&ds.tableId={{SEED}}&ds.billingProjectId={{PROJECT_ID}}&ds.isPartitioned=true&ds.refreshFields=false`,
            })
        },

        addQuery() {
            this.versionForm.queries.push({
                name: null,
                saveQueryResults: true,
                prefix: null,
                enablePartitioning: false,
                partitioning: {
                    field: '',
                },
                enableClustering: false,
                clustering: {
                    fields: '',
                },
                query: null,
            })

            const lastAccordionIndex = this.queryAccordion.length
            this.queryAccordion[lastAccordionIndex] = true
        },

        removeQuery(index) {
            this.versionForm.queries.splice(index, 1)
        },

        bindName(index, entity) {
            const {name} = this.$v.versionForm.queries.$each[index]

            const indexNumber = parseInt(index)
            const suffix = name.$model ? `- ${name.$model}` : ''

            return `${entity} ${indexNumber + 1} ${suffix}`
        },

        showSchema(index, schema) {
            const [builder] = this.$refs[`schema-builder-${index}`]

            builder.init(schema)
        },
    },
}
</script>

<style lang="scss">
#queryform-btn-row {
    margin-top: 0.5rem;
    .btn {
        margin-right: 0.3rem;
    }
}
#support-partitioning {
    margin-top: 7px;
}
.variable-row {
    margin-bottom: 5px;
    input.form-control {
        min-height: 40px;
    }
}

#custom-query-button-row {
    display: flex;

    .btn {
        margin-left: 10px;

        i {
            margin-right: 5px;
        }
    }
}

#custom-query-footer-button-row {
    .btn {
        margin-right: 10px;

        i {
            margin-right: 5px;
        }
    }
}

.input-label {
    font-family: 'Objectivity';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 10px;
    color: #000;
}

.accordion-button {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon {
        margin-left: 10px;
    }
}
.accordion-button__text {
    text-align: center;
}

.accordion-button__img {
    margin-left: 5px;
    height: 20px;
}

.accordion__queries {
    padding: 20px;
}
</style>
